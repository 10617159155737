<template>
  <div class="page">
    <div class="content">
      <!-- <div class="ad-view flex-col">
        <div class="ad-titlt flex-row align-center">
          <span>所有投放时间：</span>
          <div class="desc">付款即表示您同意在这些时间点进行广告投放</div>
        </div>
        <div
          class="ad-list"
          v-infinite-scroll="launchTimeLoad"
          infinite-scroll-distance="1"
        >
          <span v-for="(item, index) in adList" :key="index" class="adItem">{{
            item.beginUnix | dateFormat
          }}</span>
        </div>
        <div class="bg"></div>
      </div> -->
      <div class="order-info">
        <div class="order-titlt flex-row">
          <div>
            支付金额：<span style="font-size: 20rem"
              >￥{{ orderData.payAmount }}</span
            >
          </div>
          <div v-if="orderData.discountAmount > 0 && orderData.useNewDiscount">
            优惠：<span style="font-size: 20rem"
              >-￥{{ orderData.discountAmount }}</span
            >
          </div>
          <div v-if="orderData.discountAmount > 0 && orderData.useFreeVipTime">
            抵扣：<span style="font-size: 20rem"
              >-￥{{ orderData.discountAmount }}</span
            >
          </div>
        </div>
        <div class="desc-1 flex-row align-center">
          ·
          <div class="flex-row align-center" style="margin-left: 20rem">
            订单编号：{{ orderData.orderId }}，订单已提交，请在
            <div class="time">{{ minutes }}分钟{{ second }}秒</div>
            内付款，否则订单会被自动取消。
          </div>
        </div>
        <div style="margin-top: 12rem; color: #999; line-height: 22px">
          ·<span style="font-size: 16rem; margin-left: 20rem"
            >因设备或网络故障导致投放失败的部分，我们将会在全部投放结束的24小时之内，按支付比例退款退时长，退款及时长原路退回。</span
          >
        </div>
      </div>
      <div class="pay-view flex-col">
        <pay-type @clickPay="pay" :submitStatus="submitStatus"></pay-type>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import payType from "@/components/payType.vue";
import { getOrder, payOrder } from "@/api/request";
import getOrderStatus from "../../utils/getOrderStatus";

export default {
  components: {
    payType,
  },
  data() {
    return {
      adList: [],
      orderData: "",
      overtime: false,
      minutes: 0,
      second: 0,
      countDownTimer: null,
      submitStatus: false,
      pageParams: {
        statusList: [],
        orderId: "",
        Asc: true, // 升序排列
        name: "", // 屏幕名称
        pageLimit: {
          limit: 50,
          page: 1,
        },
      },
      currentPages: null,
    };
  },
  created() {
    // console.log(this.$store.state.isMobile, "this.$store.state.isMobile");
    this.pageParams.orderId = this.$route.query.orderId;
    this.getMyOrder(this.$route.query.orderId);
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    // 分页获取广告数据
    // getPageListAdEvent() {
    //   getListAd(this.pageParams).then((res) => {
    //     this.currentPages = res.pageResult.pages;
    //     this.adList = [...this.adList, ...res.adList];
    //   });
    // },
    // 投放的广告时间区域下拉到底分页加载
    // launchTimeLoad() {
    //   if (this.pageParams.pageLimit.page < this.currentPages) {
    //     this.pageParams.pageLimit.page++;
    //     console.log(
    //       "触发了下拉到底，进行分页请求，请求页码为",
    //       this.pageParams.pageLimit.page
    //     );
    //     this.getPageListAdEvent();
    //   }
    // },
    goBack() {
      // 当用户没有上一条路由的历史记录，出现点击返回按钮没有反应时，下面的代码用来判断有没有上一条路由的历史记录,如果没有则返回首页
      if (window.history.length <= 1) {
        this.$router.replace({ path: "/home" });
        return false;
      }
      this.$router.go(-1);
    },
    // 获取订单详情
    getMyOrder(orderId) {
      getOrder({
        order_id: orderId,
        include_ad: false,
      })
        .then((res) => {
          if (res.order.status == "0") {
            this.countDown(res.order.cancelTime);
            this.orderData = res.order;
          } else {
            this.$router.replace({
              name: "PayStatus",
              query: {
                orderId,
              },
            });
          }
        })
        .catch((code) => {
          // 订单不存在等需要跳转404页面的情况
          if (code == "20011" || code == "20017" || code == "10001") {
            this.$router.replace({ path: "/error" });
          }
        });
    },
    // 支付订单
    pay(type) {
      getOrderStatus(this.orderData.orderId)
        .then((status) => {
          // 如果是免审视频下单，并且当前时间超过了第一次投放时间，并且订单是待支付状态
          if (this.orderData.direct && !this.decideBeginUnix() && status == 0) {
            this.$router.push({
              name: "PayStatus",
              query: {
                orderId: this.orderData.orderId,
                directErr: 1, // 打标记，免审视频当前时间超过了第一次投放时间
              },
            });
            return;
          }
          this.requestPay(type);
        })
        .catch(() => {
          this.requestPay(type);
        });
    },
    requestPay(type) {
      this.submitStatus = true;
      payOrder({
        order_id: this.orderData.orderId,
        pay_type: type,
        is_mobile: this.isMobile,
      })
        .then(() => {
          this.submitStatus = false;
          // 新版支付，直接跳转至支付状态页
          this.$router.push({
            name: "PayStatus",
            query: {
              orderId: this.orderData.orderId,
            },
          });
          // // 如果选择了微信支付或者实付金额为0元，则直接跳转支付状态查询页
          // if (type == "1" || this.orderData.payAmount == 0) {
          //   this.$router.push({
          //     name: "PayStatus",
          //     query: {
          //       orderId: this.orderData.orderId,
          //     },
          //   });
          //   return;
          // }
          // // 如果选择了支付宝，则直接跳转支付宝第三方支付页面
          // if (type == "2") {
          //   // 加个延时器，不然跳转会被浏览器拦截
          //   setTimeout(() => {
          //     window.open(res.url, "_self");
          //   }, 100);
          // }
        })
        .catch((code) => {
          // console.log(code);
          this.submitStatus = false;
          // 订单已经支付/超时了
          if (code == "20012" || code == "20013") {
            this.$router.push({
              name: "PayStatus",
              query: {
                orderId: this.orderData.orderId,
              },
            });
          }
        });
    },
    decideBeginUnix() {
      const targetTimestamp = this.orderData.beginUnix * 1000;
      const currentTimestamp = Date.now();
      let result = null;
      // 判断当前时间是否小于第一次投放的时间
      if (currentTimestamp < targetTimestamp) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
    // 计算倒计时
    countDown(futureTime) {
      const currentTime = parseInt(new Date().getTime() / 1000);
      // console.log(futureTime, currentTime, futureTime - currentTime);
      if (currentTime >= futureTime) {
        this.overtime = true;
        return false;
      }
      let differSecond = futureTime - currentTime;
      const vm = this;
      function fn() {
        vm.minutes =
          parseInt(differSecond / 60) < 10
            ? `0${parseInt(differSecond / 60)}`
            : parseInt(differSecond / 60);
        vm.second =
          parseInt(differSecond - vm.minutes * 60) < 10
            ? `0${parseInt(differSecond - vm.minutes * 60)}`
            : parseInt(differSecond - vm.minutes * 60);
        // console.log(vm.minutes, vm.second);
        if (vm.minutes == "00" && vm.second == "00") {
          vm.clearCountDownTimer();
          vm.$router.replace({
            name: "PayStatus",
            query: {
              orderId: vm.orderData.orderId,
            },
          });
        }
        differSecond--;
        return fn;
      }
      this.countDownTimer = setInterval(fn(), 1000);
    },
    clearCountDownTimer() {
      clearInterval(this.countDownTimer);
      this.countDownTimer = null;
    },
  },
  beforeDestroy() {
    if (this.countDownTimer) {
      this.clearCountDownTimer();
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 1280rem;
  min-height: calc(100vh - 233rem);
  padding-top: 20rem;
  background: #f8f8f8;
  margin: 0 auto;
  .content {
    .ad-view {
      width: 100%;
      // padding: 37rem 48rem 0;
      padding: 37rem 8rem 0 48rem;
      background: #ffffff;
      margin-bottom: 40rem;
      border-radius: 6rem;
      .ad-titlt {
        height: 18rem;
        line-height: 18rem;
        border-left: 3rem solid #3bbcf9;
        padding-left: 14rem;
        // margin-bottom: 25rem;
        margin-bottom: 13rem;
        font-size: 18rem;
        color: #252927;

        .desc {
          // width: 316rem;
          padding: 0 8rem;
          height: 32rem;
          background: rgba(234, 145, 35, 0.08);
          border-radius: 12rem;
          text-align: center;
          line-height: 32rem;
          font-size: 15rem;
          color: #da7f0d;
          // margin-left: 30rem;
        }
      }
      .ad-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-height: 130rem;
        overflow-y: auto;
        position: relative;
        // right: 28rem;
        padding-bottom: 20rem;
        .adItem {
          font-size: 16rem;
          color: #3b3e3c;
          margin: 12rem 28rem 0 0;
        }
      }
      .bg {
        position: relative;
        bottom: 23rem;
        width: 98%;
        height: 23rem;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.5) 0%,
          #ffffff 100%
        );
      }
    }
    .order-info {
      width: 100%;
      padding: 38rem 48rem 40rem;
      background: #ffffff;
      margin-bottom: 40rem;
      border-radius: 6rem;

      .order-titlt {
        height: 18rem;
        line-height: 18rem;
        border-left: 3rem solid #3bbcf9;
        padding-left: 14rem;
        margin-bottom: 27rem;
        font-size: 18rem;
        color: #252927;
        span {
          color: #2bb3e3;
          margin-left: 14rem;
        }
        div:nth-child(2) {
          margin-left: 84rem;
          span {
            color: #2bb3e3;
            margin-left: 14rem;
          }
        }
      }
      .desc-1 {
        font-size: 16rem;
        color: #999999;
        line-height: 22rem;
        .time {
          // width: 87rem;
          padding: 0 8rem;
          height: 29rem;
          background: rgba(43, 179, 227, 0.08);
          border-radius: 12rem;
          text-align: center;
          line-height: 29rem;
          font-size: 16rem;
          color: #2bb3e3;
          margin: 0 8rem;
        }
      }
    }
    .pay-view {
      width: 100%;
      padding: 36rem 48rem 40rem;
      background: #ffffff;
      border-radius: 6rem;
    }
  }
}
::v-deep .el-dialog__body {
  display: flex;
  justify-content: center;
}
</style>
