import { getOrder } from "@/api/request";

// 根据订单id，获取订单最新状态
export default function getOrderStatus(orderId) {
  return new Promise((resolve, reject) => {
    getOrder({
      order_id: orderId,
      include_ad: false,
    })
      .then((res) => {
        resolve(res.order.status);
      })
      .catch(() => {
        reject();
      });
  });
}
